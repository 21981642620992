<template>
    <div class="signup">
        <SignUpForm />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SignUpForm from '@/components/auth/SignUpForm.vue';

export default defineComponent({
    name: 'SignupView',
    components: {
        SignUpForm,
    },
});
</script>