<template>
  <div class="course-info-container">
    <component :is="courseComponent" />
  </div>

<CourseComponent />
</template>

<script>
import { computed, defineComponent } from "vue";
import CourseComponent from "../courses/CourseComponent.vue";
import { useStore } from "vuex";
// import {CourseInfoDefault, CourseFillForm, CourseInterview, CourseTests, CourseSpecialist} from "@/components/courses/CourseInfoTemplates";
import CourseInfoDefault from "../courses/info-blocks-templates/CourseInfoDefault.vue";
import CourseFillForm from "../courses/info-blocks-templates/CourseFillForm.vue";
import CourseInterview from "../courses/info-blocks-templates/CourseInterview.vue";
import CourseTests from "../courses/info-blocks-templates/CourseTests.vue";
import CourseSpecialist from "../courses/info-blocks-templates/CourseSpecialist.vue";

export default defineComponent({
  components: {
    CourseComponent,
    CourseInfoDefault,
    CourseFillForm,
    CourseInterview,
    CourseTests,
    CourseSpecialist,
  },
  name: "TaskBlock",

  setup() {

    const store = useStore();
    const courseId = computed(() => store.getters.courseId);
    // const courseTitle = computed(() => store.state.courseTitle);
    // const courseDescription = computed(() => store.state.courseDescription);
    // const courseImage = computed(() => store.state.courseImage);

    console.log(courseId);
    
    const componentMap = {
      "default": CourseInfoDefault,
      "fill-form": CourseFillForm,
      "interview": CourseInterview,
      "tests": CourseTests,
      "specialist": CourseSpecialist,
    };

    const courseComponent = computed(() => {
      return componentMap[courseId.value] || CourseInfoDefault;
    });

    const openDrawer = () => {
      store.commit('openDrawer');
    };

    return {
      openDrawer,
      courseId,
      // courseTitle,
      // courseDescription,
      // courseImage,
      courseComponent,
    };
  },
});
</script>