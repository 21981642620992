<template>
  <div class="stepper-container">
    <ol class="stepper-box">
      <li
        v-for="(course, courseIndex) in courses"
        :key="course.id"
        class="stepper-item"
      >
        <span class="step-index">
          {{ courseIndex + 1 }}
        </span>
        <div class="stepper-head">
          <button class="stepper-title" @click="setCourseId(course.id)">
            {{ course.title }}
          </button>
          <div class="">
            <div class="stepper">
              <button
                v-for="(step, stepIndex) in course.steps"
                :key="stepIndex"
                class="stepper-step"
                :data-tooltip-target="`tooltip-${stepIndex}`"
                data-tooltip-placement="bottom"
                type="button"
                :class="{
                  'bg-green-500 text-green-100': step.status === 'completed',
                  'bg-yellow-500 text-yellow-100':
                    step.status === 'in-progress',
                  'bg-gray-400 text-gray-100 disabled opacity-30':
                    step.status === 'not-started',
                }"
                :disabled="step.status === 'not-started'"
                @click="handleStepClick(course.id, step)"
                @mouseover="showTooltip($event, courseIndex, stepIndex )"
                @mouseleave="hideTooltip"
              ></button>
            </div>
          </div>
        </div>
        <svg
          v-if="courseIndex < courses.length - 1"
          class="align-end"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 12 10"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m7 9 4-4-4-4M1 9l4-4-4-4"
          />
        </svg>
      </li>

      <div v-if="tooltipCourseIndex !== null && tooltipStepIndex !== null" class="tooltip" :style="tooltipStyle">
        {{ courses[tooltipCourseIndex].steps[tooltipStepIndex].title }}
      </div>
    </ol>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "StepperBlock",

  setup() {
    const store = useStore();
    const setCourseId = (courseId: string) => {
      console.log("Устанавливаем ID:", courseId);
      store.commit("setCourseId", courseId);
    };
    const tooltipStepIndex = ref<number | null>(null);
    const tooltipCourseIndex = ref<number | null>(null);
    const tooltipStyle = ref({});

    const courses = [
      {
        id: "fill-form",
        title: "Fill the form",
        description: "Step details here",

        progress: 1,
        status: "completed",
        steps: [
          {
            title: "Step 1",
            description: "Step 1 details here",
            status: "completed",
          },

          {
            title: "Step 2",
            description: "Step 2 details here",
            status: "in-progress",
          },

          {
            title: "Step 2",
            description: "Step 2 details here",
            status: "in-progress",
          },

          {
            title: "Step 2",
            description: "Step 2 details here",
            status: "not-started",
          },

          {
            title: "Step 3",
            description: "Step 3 details here",
            status: "not-started",
          },
        ],
      },
      {
        id: "interview",
        title: "1st Interview",
        description: "Step details here",
        body: "Content",
        progress: 0.6,
        status: "in-progress",
        steps: [
          {
            title: "Step 1",
            description: "Step 1 details here",
            status: "completed",
          },

          {
            title: "Step 2",
            description: "Step 2 details here",
            status: "in-progress",
          },

          {
            title: "Step 3",
            description: "Step 3 details here",
            status: "not-started",
          },

          {
            title: "Step 4",
            description: "Step 4 details here",
            status: "not-started",
          },

          {
            title: "Step 5",
            description: "Step 5 details here",
            status: "not-started",
          },
        ],
      },
      {
        id: "tests",
        title: "Tests",
        description: "Step details here",
        progress: 0,
        status: "not-started",
        steps: [
          {
            title: "Step 1",
            description: "Step 1 details here",
            status: "completed",
          },

          {
            title: "Step 2",
            description: "Step 2 details here",
            status: "in-progress",
          },

          {
            title: "Step 2",
            description: "Step 2 details here",
            status: "in-progress",
          },

          {
            title: "Step 2",
            description: "Step 2 details here",
            status: "in-progress",
          },

          {
            title: "Step 3",
            description: "Step 3 details here",
            status: "not-started",
          },
        ],
      },
      {
        id: "specialist",
        title: "Conversation",
        description: "Step details here",
        progress: 0,
        status: "not-started",
        steps: [
          {
            title: "Step 1",
            description: "Step 1 details here",
            status: "completed",
          },

          {
            title: "Step 2",
            description: "Step 2 details here",
            status: "in-progress",
          },

          {
            title: "Step 2",
            description: "Step 2 details here",
            status: "in-progress",
          },

          {
            title: "Step 2",
            description: "Step 2 details here",
            status: "in-progress",
          },

          {
            title: "Step 3",
            description: "Step 3 details here",
            status: "not-started",
          },
        ],
      },
    ];

    const showTooltip = (
      event: MouseEvent,
      courseIndex: number,
      stepIndex: number
    ) => {
      tooltipStepIndex.value = stepIndex;
      tooltipCourseIndex.value = courseIndex;
      tooltipStyle.value = {
        top: `${event.clientY + 10}px`,
        left: `${event.clientX}px`,
      };
    };

    const hideTooltip = () => {
      tooltipStepIndex.value = null;
      tooltipCourseIndex.value = null;
    };

    const handleStepClick = (courseId: string, step: any) => {
      console.log(`Clicked on step ${step.title} of course ${courseId}`);
      // Добавить здесь логику для обработки клика по шагу
    };
    return {
      courses,
      setCourseId,
      handleStepClick,
      tooltipStepIndex,
      tooltipCourseIndex,
      tooltipStyle,
      showTooltip,
      hideTooltip,
    };
  },
});
</script>

<style scoped>
.tooltip {
  position: fixed;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 10;
  opacity: 0.9;
}
</style>