import { MutationTree } from 'vuex';
import { RootState, UserInfo } from '@/types/types';

function getDefaultState() {
  return {
    darkMode: false,
    isLogged: false,
    accessToken: '',
    refreshToken: '',
    authServiceStatus: false,
    userInfo: {
      userId: '',
      email: '',
      name: '',
      surname: '',
      avatar: '',
      role: '',
      familyId: '',
    },
  };
}

export const mutations: MutationTree<RootState> = {
  setLanguage(state, language: string) {
    state.language = language;
  },
  setDarkMode(state, value:boolean) {
    state.darkMode = value;
  },
  setAccessToken(state, token: string) {
    state.accessToken = token;
  },
  setRefreshToken(state, token: string) {
    state.refreshToken = token;
  },
  clearToken(state) {
    state.accessToken = '';
    state.refreshToken = '';
  },
  setAuthServiceStatus(state, status: boolean) {
    state.authServiceStatus = status;
  },
  setLoggedStatus(state, status: boolean) {
    sessionStorage.setItem('isLogged', status.toString());
    state.isLogged = status;
  },
  setUserInfo(state, userInfo: UserInfo) {
    state.userInfo = userInfo;
  },
  clearUserInfo(state) {
    state.userInfo = {
      id: '',
      email: '',
      name: '',
      surname: '',
      avatar: '',
      role: '',
      familyId: '',
    };
  },
  setUserId(state, userId: string) {
    state.userInfo.id = userId;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  toggleDrawer(state) {
    state.isDrawerOpen = !state.isDrawerOpen;
  },
  openDrawer(state) {
    state.isDrawerOpen = true;
  },
  closeDrawer(state) {
    state.isDrawerOpen = false;
  },
  setCourseId(state, courseId:string) {
    console.log('courseId', courseId);  
    state.courseInfo.id = courseId;
  },
  clearCourseId(state) {
    state.courseInfo.id = '';
  },
  toggleAside(state) {
    state.isAsideCollapsed = !state.isAsideCollapsed;
  },
};