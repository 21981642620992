<template>
    <!-- Подложка -->
    <div
      v-if="isDrawerOpen"
      id="course-drawer-overlay"
      class="course-drawer-overlay fixed inset-0 bg-black transition-opacity duration-300"
      :class="{ 'opacity-20': isDrawerOpen, 'opacity-0': !isDrawerOpen }"
      @click="closeDrawer"
    ></div>

    <!-- drawer component -->
    <div
      id="course-drawer"
      :class="[
        'course-drawer-container fixed top-0 right-0 z-50 h-screen p-4 overflow-y-auto transition-transform duration-300 bg-white dark:bg-gray-800',
        { 'translate-x-full': !isDrawerOpen, 'translate-x-0': isDrawerOpen },
      ]"
      tabindex="-1"
      aria-labelledby="course-drawer-label"
      @click.stop
    >
      <div class="bg-white" style="width:30rem;">
        <button
          type="button"
          @click="closeDrawer"
          aria-controls="course-drawer"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <svg
            class="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span class="sr-only">Close menu</span>
        </button>

        <component :is="drawerComponent" />  
      </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import CourseOkTemplate from "./course_template/CourseOkTemplate.vue";

export default defineComponent({
  name: "CourseComponent",
  components: {
    CourseOkTemplate,
  },
  setup() {
    const store = useStore();
    const isDrawerOpen = computed(() => store.getters.isDrawerOpen);
    const drawerCourseId = computed(() => store.getters.courseId);
    
    const componentMap: { [key: string]: any } = {
      "default": CourseOkTemplate,
      "fill-form": CourseOkTemplate,
      // "interwiew": InterwiewTemplate,
      // "tests": TestsTemplate,
      // "specialist": SpecialistTemplate,
    };

    const drawerComponent = computed(() => {
      return componentMap[drawerCourseId.value] || CourseOkTemplate;
    });

    const closeDrawer = () => {
      store.commit('closeDrawer');
    };
    return {
      isDrawerOpen,
      drawerCourseId,
      closeDrawer,
      drawerComponent,
    };
  },
});
</script>