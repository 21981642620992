<template>
    <div class="gallery">
        <div class="gallery-container">
        <GalleryBlock />
        </div>
    </div> 
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import GalleryBlock from "@/components/gallery/GalleryBlock.vue";

export default defineComponent({
    name: "GalleryView",
    components: {
        GalleryBlock,
    },
});

</script>