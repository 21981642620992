import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/home/HomeView.vue";
import SigninView from "@/views/auth/SigninView.vue";
import SignupView from "@/views/auth/SignupView.vue";
import UserProfile from "@/views/users/UserProfile.vue";
import FamilyProfile from "@/views/family/FamilyProfile.vue";
import NotFound from "@/views/NotFound.vue";
import GalleryView from "@/views/gallery/GalleryView.vue";
import CalendarView from "@/views/calendar/CalendarView.vue";
import MessagesView from "@/views/messages/MessagesView.vue";
import { isAuthenticated, checkToken } from "@/services/auth/authService";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Home",
      requiresAuth: true,
      requiredRole: "admin",
    },
  },
  {
    path: "/signin",
    name: "signin",
    component: SigninView,
    meta: { title: "Sign In", requiresAuth: false },
  },
  {
    path: "/signup",
    name: "signup",
    component: SignupView,
    meta: { title: "Sign Up" },
  },

  {
    path: "/user/:id",
    name: "user",
    component: UserProfile,
    meta: { title: "User Profile", requiresAuth: true },
  },

  {
    path: "/family/:id",
    name: "family",
    component: FamilyProfile,
    meta: { title: "Family page", requiresAuth: true },
  },
  {
    path: "/messages",
    name: "messages",
    component: MessagesView,
    meta: {
      title: "Messages",
      requiresAuth: true,
    },
  },
  {
    path: "/gallery",
    name: "gallery",
    component: GalleryView,
    meta: {
      title: "Gallery",
      requiresAuth: true,
    },
  },
  {
    path: "/calendar",
    name: "calendar",
    component: CalendarView,
    meta: {
      title: "Calendar",
      requiresAuth: true,
    },
  },
  {
    path: "/:pathMatch(.*)*", // Обрабатывает все несуществующие пути
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Навигационный охранник для установки заголовка страницы
router.afterEach((to) => {
  const defaultTitle = "Genki Nest"; // Замените на название вашего сайта
  document.title = to.meta.title
    ? `${to.meta.title} | ${defaultTitle}`
    : defaultTitle;
});

// Навигационный охранник для проверки аутентификации и роли
router.beforeEach(async (to, from, next) => {
  const isLogged = await isAuthenticated();
  // const isLogged = store.state.isLogged;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isLogged) {
      console.log("Not authenticated");
      next({ name: "signin" });
    } else {
      next();
    }
  } else if (to.name === "signin" || to.name === "signup") {
    if (isLogged) {
      console.log("Already authenticated");
      next({ name: "home" }); // Замените на имя вашего главного маршрута
    } else {
      next();
    }
  } else {
    next();
  }
});

// Check UserData
// router.beforeEach(async (to, from, next) => {
//   const userId = sessionStorage.getItem("userId");

//   if (userId) {
//     console.log("User ID:", userId);
//     await store.dispatch("getUserInfoById", { userId });
//     next();
//   } else {
//     console.log("User ID not found");
//     next();
//   }
// });

export default router;
