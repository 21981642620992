import { RootState } from '@/types/types';


// Определение типа состояния
export const state: RootState = {
  language: 'ru',
  darkMode: false,
  isLogged: true,
  isDrawerOpen: false,
  isAsideCollapsed: false,
  courseId: '', 
  accessToken: '',
  refreshToken: '',
  authServiceStatus: false,
  userInfo: {
    id: '',
    email: '',
    name: '',
    surname: '',
    avatar: '',
    role: '',
    familyId: '',
  },
  courseInfo: {
    id: 'default',
    title: '',
    description: '',
    image: '',
  },
};

