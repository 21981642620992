import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/courses/4.jpg'


const _hoisted_1 = { class: "task" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<a href=\"#\"><img class=\"rounded-t-lg\" src=\"" + _imports_0 + "\" alt=\"\"></a><div class=\"p-5\"><a href=\"#\"><h5 class=\"mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white\"> Tests </h5></a><p class=\"mb-3 font-normal text-gray-700 dark:text-gray-400\"> Check your steps on the left and choose the best course for you. Click on the course to see more details. </p></div>", 2)
  ])))
}