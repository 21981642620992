<template>
        <h5
      id="course-drawer-label"
      class="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400"
    >

    </h5>
        
    <p class="mb-6 text-sm text-gray-500 dark:text-gray-400">
      Supercharge your hiring by taking advantage of our
      <a
        href="#"
        class="text-blue-600 underline font-medium dark:text-blue-500 hover:no-underline"
        >limited-time sale</a
      >
      for Flowbite Docs + Job Board. Unlimited access to over 190K top-ranked
      candidates and the #1 design job board.
    </p>
    <div class="grid grid-cols-2 gap-4">
      <a
        href="#"
        class="px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        >Learn more</a
      >
      <a
        href="#"
        class="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        >Get access

      </a>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";


export default defineComponent({
  name: "CourseOkTemplate",
});

</script>