import Cookies from "js-cookie";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import store from "@/store";

interface JwtPayload {
  exp: number;
}

const AUTH_URL = process.env.VUE_APP_AUTH_URL || "http://localhost:3000";
// const SECRET_KEY = process.env.VUE_APP_SECRET_KEY || "secret";
const REFRESH_THRESHOLD = 5 * 60 * 1000; // 5 minutes

async function getFingerprint() {
  const fp = await FingerprintJS.load();
    const result = await fp.get();
    const fingerprint = result.visitorId;
    console.log(fingerprint);
    return fingerprint;
}

// async function checkToken(token: string): Promise<{ userId: string, tokenType: string, expiresIn: number }> {
async function checkToken(token: string): Promise<boolean> {
  // Получение фингерпринта
  const fingerprint = await getFingerprint();

  const response = await fetch(`${AUTH_URL}/check-token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-nb-fingerprint": fingerprint,
      // credentials: "include",
    },
    body: JSON.stringify({
      token: token,
      fingerprint: fingerprint,
      target: "",
    }),
  });

  const data = await response.json();

  if (!response.ok) {
    console.error("Ошибка при проверке токена");
    return false;
  }
  console.log("Токен проверен");
    if (!data.userId || data.userId.trim() === "") {
      return false;
    }

    console.log(data);
  return true;
  // return {
  //   userId: data.userId,
  //   tokenType: data.tokenType,
  //   expiresIn: data.expiresIn
  // };
}

async function checkExpiration(expirationDate: string): Promise<boolean> {
  const expirationTime = new Date(expirationDate).getTime();
  const currentTime = new Date().getTime();
  const remainingTime = expirationTime - currentTime;
  if (expirationTime <= currentTime) {
    // Время истекло, выбросить из сеанса
    console.log("Время истекло, выбросить из сеанса");
    logout();
    return false;
  } else if (expirationTime - currentTime <= REFRESH_THRESHOLD) {

    try {
      // До истечения времени осталось меньше 10 минут, запросить новый токен
      console.log("До истечения времени осталось меньше 10 минут, запросить новый токен");
      await refreshToken();
      return true;
    } catch (error) {
      console.error("Ошибка при обновлении токена:", error);
      return false;
    }
  }
  else {
    console.log("Время действия токена еще не истекло");
    // Вывод оставшегося времени в удобном формате
  const hours = Math.floor(remainingTime / (1000 * 60 * 60));
  const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

  console.log(`Оставшееся время действия токена: ${hours}ч ${minutes}м ${seconds}с`);
    
    return true;
  }
}

async function isAuthenticated(): Promise<boolean> {
  // Получение токена из cookies
  const token = sessionStorage.getItem("accessToken");
  const isLogged = sessionStorage.getItem("isLogged");
  const expiration = sessionStorage.getItem("tokenExpiration");

  if (!isLogged) {
    await clearInfo();
    return false
  }

  if (!token) {
    await clearInfo();
    return false;
  }

  if (!expiration) {
    await clearInfo();
    return false;
  }

  try {
    // Проверка времени жизни токена
    await checkExpiration(expiration);

    return true;
  } catch (error) { 

    await clearInfo();
    return false;
  }

  // try {
  //   // Отправка запроса на сервер для проверки токена
  //   const isTokenValid = await checkToken(token);

  //   if (!isTokenValid) {
  //     await clearInfo();
  //     return false;
  //   }

  //   store.commit("setLoggedStatus", true);

  //   return true;
  // } catch (error) {
  //   await clearInfo();
  //   return false;
  // }
}

async function login(credentials: { email: string; password: string }) {
  try {
    const fingerprint = await getFingerprint();
    const response = await fetch(`${AUTH_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-nb-fingerprint": fingerprint,
        // credentials: "include",
      },
      body: JSON.stringify({ ...credentials, fingerprint }),
    });
    console.log(response);
    if (!response.ok) {
      throw new Error("Ошибка авторизации");
    }
    const data = await response.json();

    return data;
  } catch (error) {
    throw new Error('Ошибка при логине: ' + error);
  }
}

async function register(credentials: { email: string; password: string }) {
  try {
    const fingerprint = await getFingerprint();

    const response = await fetch(`${AUTH_URL}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-nb-fingerprint": fingerprint,
      },
      body: JSON.stringify({ ...credentials, fingerprint }),
    });

    if (!response.ok) {
      throw new Error("Ошибка регистрации");
    }
    const data = await response.json();

    return data;
  } catch (error) {
    throw new Error('Ошибка при регистрации: ' + error);
  }
}

async function getTokenExpiration(): Promise<Date> {
  const currentTime = new Date().getTime();
  const hours = 0;
  const minutes = 30;
  const seconds = 0;
  const expirationTimeInMilliseconds = (hours * 60 * 60 + minutes * 60 + seconds) * 1000;
  const expirationDate = new Date(currentTime + expirationTimeInMilliseconds);

  return expirationDate;
}

async function refreshToken() {
  try {
    const refreshToken = sessionStorage.getItem("refreshToken");
    const fingerprint = await getFingerprint();
    if (!refreshToken) {
      throw new Error("Отсутствует refreshToken");
    }

    const response = await fetch(`${AUTH_URL}/refresh-access-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refreshToken, fingerprint }),
    });

    if (!response.ok) {
      throw new Error("Ошибка при обновлении токена");
    }

    const data = await response.json();
    // Set new Access Token and Expiration Date
    sessionStorage.setItem("accessToken", data.accessToken);
    const tokenExpiration = await getTokenExpiration();
    sessionStorage.setItem("tokenExpiration", tokenExpiration.toString());
    return data;
  } catch (error) {
    throw new Error('Ошибка при обновлении токена: ' + error);
  }
}

async function logout() {
  await clearInfo();
  console.log("Пользователь вышел из системы");
}

async function clearInfo() {
  // Очистка cookies
  Cookies.remove("accessToken");
  Cookies.remove("refreshToken");

  // Очистка sessionStorage
  sessionStorage.clear();

  // Очистка localStorage
  localStorage.clear();

  // Очистка store
  store.commit("resetState");

}
export { isAuthenticated, checkToken, login, logout, refreshToken, register, clearInfo, getTokenExpiration };
