import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "navbar" }
const _hoisted_2 = { class: "navbar-container" }
const _hoisted_3 = { class: "logo-container" }
const _hoisted_4 = {
  href: "/",
  class: ""
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 0,
  class: "navbar-links-container",
  id: "navbar-user"
}
const _hoisted_7 = { class: "h1" }
const _hoisted_8 = {
  key: 1,
  class: "navbar-auth-container"
}
const _hoisted_9 = { class: "auth-menu flex items-end justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DiagLines = _resolveComponent("DiagLines")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_LanguageSwitcher = _resolveComponent("LanguageSwitcher")!
  const _component_ThemeSwitcher = _resolveComponent("ThemeSwitcher")!
  const _component_NotificationIcon = _resolveComponent("NotificationIcon")!
  const _component_UserProfileMenu = _resolveComponent("UserProfileMenu")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", _hoisted_4, [
          _createElementVNode("img", {
            src: _ctx.logo,
            class: "logo-img",
            alt: "Genki Nest Logo"
          }, null, 8, _hoisted_5),
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "" }, "Genki Nest", -1))
        ]),
        (_ctx.isLogged)
          ? (_openBlock(), _createBlock(_component_DiagLines, {
              key: 0,
              class: "mx-4"
            }))
          : _createCommentVNode("", true),
        (_ctx.isLogged)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 1,
              to: { name: 'family', params: { id: 1 } },
              class: "navbar-link"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("山田 (Yamada)")
              ])),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isLogged)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("h1", _hoisted_7, _toDisplayString(_ctx.currentRouteName), 1)
          ]))
        : _createCommentVNode("", true),
      (!_ctx.isLogged)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_router_link, {
              to: "/signin",
              class: "text-sm text-gray-900 dark:text-white"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Sign in")
              ])),
              _: 1
            }),
            _cache[4] || (_cache[4] = _createElementVNode("span", { class: "mx-2" }, " | ", -1)),
            _createVNode(_component_router_link, {
              to: "/signup",
              class: "text-sm text-gray-900 dark:text-white"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Sign up")
              ])),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", {
          class: _normalizeClass([{ hidden: !_ctx.isLogged }, "language-switcher-container"])
        }, [
          _createVNode(_component_LanguageSwitcher)
        ], 2),
        (_ctx.isLogged)
          ? (_openBlock(), _createBlock(_component_DiagLines, {
              key: 0,
              class: "mx-4"
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass([{ hidden: !_ctx.isLogged }, "theme-switcher-container"])
        }, [
          _createVNode(_component_ThemeSwitcher)
        ], 2),
        (_ctx.isLogged)
          ? (_openBlock(), _createBlock(_component_DiagLines, {
              key: 1,
              class: "mx-4"
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass([{ hidden: !_ctx.isLogged }, "notifications-container"])
        }, [
          _createVNode(_component_NotificationIcon)
        ], 2),
        (_ctx.isLogged)
          ? (_openBlock(), _createBlock(_component_DiagLines, {
              key: 2,
              class: "mx-4"
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass([{ hidden: !_ctx.isLogged }, "user-profile-container"])
        }, [
          _createVNode(_component_UserProfileMenu)
        ], 2)
      ])
    ])
  ]))
}