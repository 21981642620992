<template>
  <nav class="navbar">
    <div class="navbar-container">
      <!-- Logo -->
      <div class="logo-container">
        <a href="/" class="">
          <img :src="logo" class="logo-img" alt="Genki Nest Logo" />
          <span class="">Genki Nest</span>
        </a>
        <DiagLines v-if="isLogged" class="mx-4" />

        <router-link
          v-if="isLogged"
          :to="{ name: 'family', params: { id: 1 } }"
          class="navbar-link"
          >山田 (Yamada)</router-link
        >
      </div>

      <!-- Navbar Rout -->
      <div v-if="isLogged" class="navbar-links-container" id="navbar-user">
        <h1 class="h1">{{ currentRouteName }}</h1>
      </div>

      <!-- Auth links -->
      <div class="navbar-auth-container" v-if="!isLogged">
        <router-link to="/signin" class="text-sm text-gray-900 dark:text-white"
          >Sign in</router-link
        >
        <span class="mx-2"> | </span>
        <router-link to="/signup" class="text-sm text-gray-900 dark:text-white"
          >Sign up</router-link
        >
      </div>

      <!-- User block -->
      <div class="auth-menu flex items-end justify-end">
        
        <!-- Language Switcher-->
        <div v-bind:class="{ hidden: !isLogged }" class="language-switcher-container">
          <LanguageSwitcher />
        </div>

        <DiagLines v-if="isLogged" class="mx-4" />

        <!-- Theme Switcher -->
        <div v-bind:class="{ hidden: !isLogged }" class="theme-switcher-container">
          <ThemeSwitcher />
        </div>

        <DiagLines v-if="isLogged" class="mx-4" />

        <!-- Notification Switcher-->
        <div v-bind:class="{ hidden: !isLogged }" class="notifications-container">
          <NotificationIcon />
        </div>

        <DiagLines v-if="isLogged" class="mx-4" />
        <!-- User Profile Menu -->
        <div v-bind:class="{ hidden: !isLogged }" class="user-profile-container">
          <UserProfileMenu />
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import logo from "@/assets/img/logo/genkinest-logo1.png";
import { useRoute } from "vue-router";
import DiagLines from "@/components/svg/vectors/2diaglines.vue";
import ThemeSwitcher from "./top/ThemeSwitcher.vue";
import UserProfileMenu from "./top/UserProfileMenu.vue";
import LanguageSwitcher from "./top/LanguageSwitcher.vue";
import NotificationIcon from "@/components/svg/icons/NotificationIcon.vue";

export default defineComponent({
  name: "NavbarTop",
  components: {
    DiagLines,
    LanguageSwitcher,
    ThemeSwitcher,
    UserProfileMenu,
    NotificationIcon,
  },
  props: {
    isLogged: Boolean,
  },
  setup() {
    const route = useRoute();

    const currentRouteName = computed(() => route.meta.title);

    return {
      logo,
      currentRouteName,
    };
  },
});
</script>
