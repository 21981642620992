<template>
  <div class="sidebar-menu">
    <ul class="space-y-2 font-medium">
      <li clas="" v-for="route in routes" :key="route.name">
        <router-link
          :to="route.href"
          class="sidebar-menu-item flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
        >
          <component :is="route.icon" class="w-6 h-6 ms-1" />
          <span v-if="!isCollapsed" class="ms-3">{{ route.name }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { HomeIcon, FamilyIcon, MessageIcon, GalleryIcon, CalendarIcon} from "@/components/svg/icons/IconsComponents";
export default defineComponent({
  name: "AsideMenu",
  components: {
    HomeIcon,
    FamilyIcon,
    MessageIcon,
    GalleryIcon,
    CalendarIcon,
  },
  props: {
    isCollapsed: {
      type: Boolean,
      required: true,
    },
  },
  setup() {

    const routes = [
      {
        name: "Home",
        icon: "HomeIcon",
        href: "/",
      },
      {
        name: "My Family",
        icon: "FamilyIcon",
        href: "/family/1",
      },
      {
        name: "Messages",
        icon: "MessageIcon",
        href: "/messages",
      },
      {
        name: "Gallery",
        icon: "GalleryIcon",
        href: "/gallery",
      },
      {
        name: "Calendar",
        icon: "CalendarIcon",
        href: "/calendar",
      },
    ];
    return {
      routes,

    };
  },
});
</script>