<template>
  <div class="article-container">
    <header class="article-header">
      <img class="article-image rounded-t-lg" src="@/assets/img/courses/2.jpg" alt="Course Image" />

      <h1 class="article-title">Feel free to choose the best course for you</h1>
      <p class="article-subtitle">Check your steps on the left and choose the best course for you. Click on the course to see more details.</p>
    </header>
    <div class="article-content">
      <section class="article-section">
        <h2 class="section-title">Introduction</h2>
        <p class="section-text">Welcome to our comprehensive guide on choosing the best course for your needs. In this article, we will explore various factors to consider when selecting a course, including your personal goals, learning style, and available resources.</p>
      </section>
      <section class="article-section">
        <h2 class="section-title">Factors to Consider</h2>
        <p class="section-text">When choosing a course, it's important to consider the following factors:</p>
        <ul class="section-list">
          <li class="list-item">Your personal goals and objectives</li>
          <li class="list-item">The course content and curriculum</li>
          <li class="list-item">The qualifications and experience of the instructors</li>
          <li class="list-item">The format and delivery method of the course</li>
          <li class="list-item">The cost and availability of financial aid</li>
        </ul>
      </section>
      <section class="article-section">
        <h2 class="section-title">Conclusion</h2>
        <p class="section-text">Choosing the right course can be a challenging task, but by considering the factors outlined in this guide, you can make an informed decision that aligns with your personal and professional goals. Remember to take your time, do your research, and choose a course that best fits your needs.</p>
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "CourseInfoDefault",
  setup() {
    const store = useStore();
    const openDrawer = () => {
      store.commit("openDrawer", "course-drawer");
    };

    return {
      openDrawer,
    };
  },
});
</script>

<style scoped>
.article-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.article-header {
  text-align: center;
  margin-bottom: 20px;
}

.article-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
}

.article-subtitle {
  font-size: 1.25rem;
  color: #666;
}

.article-content {
  line-height: 1.6;
  color: #444;
}

.article-image {
  width: 100%;
  height: 30vh;
  margin-bottom: 20px;
  object-fit: cover;
}

.article-section {
  margin-bottom: 20px;
}

.section-title {
  font-size: 1.75rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.section-text {
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;
}

.section-list {
  list-style-type: disc;
  padding-left: 20px;
}

.list-item {
  margin-bottom: 5px;
  color: #555;
}
</style>