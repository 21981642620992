<template>
    <ScheduleComponent/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ScheduleComponent from '@/components/calendar/ScheduleComponent.vue';

export default defineComponent({
    name: 'CalendarView',
    components: {
        ScheduleComponent,
    },
});

</script>