<template>
  <div class="theme-switcher" >
    <div :class="['icon-container', { flipped: isFlipped }]" @click="toggleTheme">
      <div class="icon icon-sun">
        <!-- Первая иконка -->
        <SunIcon />
      </div>
      <div class="icon icon-moon">
        <!-- Вторая иконка -->
        <MoonIcon />   
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { MoonIcon, SunIcon,  } from "@/components/svg/icons/IconsComponents";
import { useStore } from "vuex";

export default defineComponent({
  name: "ThemeSwitcher",
  components: {
    MoonIcon,
    SunIcon,

  },
  setup() {
    const store = useStore();
    const isFlipped = ref(false);
    const isDarkMode = ref(false);

    const toggleTheme = () => {
      // Меняем значение переменной
      isFlipped.value = !isFlipped.value;
      // Изменяем значение и обновляем атрибут на теге <html>
      isDarkMode.value = !isDarkMode.value;
      store.commit("setDarkMode", isDarkMode.value);
      console.log(isDarkMode.value);
      document.documentElement.setAttribute(
        "data-theme",
        isDarkMode.value ? "dark" : "light"
      );
    };
    return {
      isFlipped,
      isDarkMode,
      toggleTheme,
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}




</style>
