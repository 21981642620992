<template>
    <div>
        <h1>Messages</h1>
    </div>

</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'MessagesView',
});

</script>