import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-profile" }
const _hoisted_2 = {
  class: "user-icon",
  type: "button",
  id: "user-menu-button",
  "aria-expanded": "false",
  "data-dropdown-toggle": "user-dropdown",
  "data-dropdown-placement": "bottom"
}
const _hoisted_3 = {
  class: "z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600",
  id: "user-dropdown"
}
const _hoisted_4 = { class: "px-4 py-3" }
const _hoisted_5 = { class: "block text-sm text-gray-900 dark:text-white" }
const _hoisted_6 = { class: "block text-sm text-gray-500 truncate dark:text-gray-400" }
const _hoisted_7 = {
  class: "py-2",
  "aria-labelledby": "user-menu-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UnderfindUserIcon = _resolveComponent("UnderfindUserIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("span", { class: "sr-only" }, "Open user menu", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_UnderfindUserIcon)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, [
          (_ctx.userId)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: { name: 'user', params: { id: _ctx.userId } },
                class: "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.userInfo.name), 1)
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.userInfo.email), 1)
      ]),
      _createElementVNode("ul", _hoisted_7, [
        _cache[1] || (_cache[1] = _createElementVNode("li", null, [
          _createElementVNode("a", {
            href: "#",
            class: "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
          }, "Settings")
        ], -1)),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args))),
            href: "#",
            class: "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
          }, "Sign out")
        ])
      ])
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("button", {
      "data-collapse-toggle": "navbar-user",
      type: "button",
      class: "inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600",
      "aria-controls": "navbar-user",
      "aria-expanded": "false"
    }, [
      _createElementVNode("span", { class: "sr-only" }, "Open main menu"),
      _createElementVNode("svg", {
        class: "w-5 h-5",
        "aria-hidden": "true",
        xmlns: "http://www.w3.org/2000/svg",
        fill: "none",
        viewBox: "0 0 17 14"
      }, [
        _createElementVNode("path", {
          stroke: "currentColor",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2",
          d: "M1 1h15M1 7h15M1 13h15"
        })
      ])
    ], -1))
  ]))
}