<template>
  <div class="home">
    <div class="courses-container">
      <StepperBlock />
      <div class="course">
        <CourseInfoBlock />
      </div>
      
      <!-- <TimelineBlock /> -->
    </div>
    <!-- <div class="info-container">123</div> -->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import StepperBlock from "@/components/home/StepperBlock.vue";
import CourseInfoBlock from "@/components/home/CourseInfoBlock.vue";
import TimelineBlock from "@/components/home/TimelineBlock.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    StepperBlock,
    CourseInfoBlock,
    // TimelineBlock,
  },

  setup() {
    const store = useStore();
    const userId = sessionStorage.getItem("userId");

    // store.dispatch('pingAuthServer');

    onMounted(() => {
      store.dispatch("getUserInfoById", { userId });
    });

    return { userId };
  },
});
</script>
