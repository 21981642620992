import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "2rem",
  height: "2.1rem",
  viewBox: "0 0 60 60",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("line", {
      x1: "10",
      y1: "55",
      x2: "35",
      y2: "10",
      stroke: "#A5B68D",
      "stroke-width": "3"
    }, null, -1),
    _createElementVNode("line", {
      x1: "25",
      y1: "55",
      x2: "50",
      y2: "10",
      stroke: "#A5B68D",
      "stroke-width": "3"
    }, null, -1)
  ])))
}