import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "space-y-6",
  action: "#"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"])),
    class: "w-full m-auto max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700"
  }, [
    _createElementVNode("form", _hoisted_1, [
      _cache[5] || (_cache[5] = _createElementVNode("h5", { class: "text-xl font-medium text-gray-900 dark:text-white" }, " Sign in to our platform ", -1)),
      _createElementVNode("div", null, [
        _cache[3] || (_cache[3] = _createElementVNode("label", {
          for: "email",
          class: "block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        }, "Your email", -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
          type: "email",
          name: "email",
          id: "email",
          class: "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white",
          placeholder: "name@company.com",
          required: ""
        }, null, 512), [
          [_vModelText, _ctx.email]
        ])
      ]),
      _createElementVNode("div", null, [
        _cache[4] || (_cache[4] = _createElementVNode("label", {
          for: "password",
          class: "block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        }, "Your password", -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
          type: "password",
          name: "password",
          id: "password",
          placeholder: "••••••••",
          class: "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white",
          required: ""
        }, null, 512), [
          [_vModelText, _ctx.password]
        ])
      ]),
      _cache[6] || (_cache[6] = _createStaticVNode("<div class=\"flex items-start\"><div class=\"flex items-start\"><div class=\"flex items-center h-5\"><input id=\"remember\" type=\"checkbox\" value=\"\" class=\"w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800\"></div><label for=\"remember\" class=\"ms-2 text-sm font-medium text-gray-900 dark:text-gray-300\">Remember me</label></div><a href=\"#\" class=\"ms-auto text-sm text-blue-700 hover:underline dark:text-blue-500\">Lost Password?</a></div><button type=\"submit\" class=\"w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800\"> Login to your account </button><div class=\"text-sm font-medium text-gray-500 dark:text-gray-300\"> Not registered? <a href=\"#\" class=\"text-blue-700 hover:underline dark:text-blue-500\">Create account</a></div>", 3))
    ])
  ], 32))
}