import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "courses-container" }
const _hoisted_3 = { class: "course" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StepperBlock = _resolveComponent("StepperBlock")!
  const _component_CourseInfoBlock = _resolveComponent("CourseInfoBlock")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_StepperBlock),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_CourseInfoBlock)
      ])
    ])
  ]))
}