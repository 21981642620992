import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass([{ 'dark-mode': _ctx.isDarkMode }, "icon multi-color"]),
    viewBox: "0 0 24 24",
    id: "sun",
    xmlns: "http://www.w3.org/2000/svg"
  }, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g id=\"SVGRepo_bgCarrier\" stroke-width=\"0\"></g><g id=\"SVGRepo_tracerCarrier\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></g><g id=\"SVGRepo_iconCarrier\"><title style=\"stroke-width:2;\">sun</title><circle id=\"primary-fill\" cx=\"12\" cy=\"12\" r=\"4\" style=\"fill:none;stroke-width:2;\"></circle><path id=\"secondary-stroke\" d=\"M12,3V4M5.64,5.64l.7.7M3,12H4m1.64,6.36.7-.7M12,21V20m6.36-1.64-.7-.7M21,12H20M18.36,5.64l-.7.7\" style=\"stroke-linecap:round;stroke-linejoin:round;stroke-width:2;\"></path></g>", 3)
  ]), 2))
}