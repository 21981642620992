<template>
  <FamilyProfileCard :userInfo="userInfo" />
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed } from "vue";
import { useStore } from "vuex";
import FamilyProfileCard from "@/components/family/FamilyProfileCard.vue";

export default defineComponent({
  name: "FamilyProfile",
  components: {
    FamilyProfileCard,
  },
  setup() {
    const store = useStore();
    const userInfo = computed(() => store.getters.userInfo);
    const userId = ref(null);

    watch(
      () => userInfo.value,
      (newUserInfo) => {
        if (newUserInfo && newUserInfo.id) {
          userId.value = newUserInfo.id;
          console.log("userId:", userId.value);
        }
      },
      { immediate: true }
    );

    return {
      userId,
      userInfo,
    };
  },
});
</script>