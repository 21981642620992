<template>
  <aside :class="['aside', { collapsed: isAsideCollapsed }]"
    v-if="isLogged"
    id="logo-sidebar"
    class="sidebar  w-64 h-screen transition-transform -translate-x-full sm:translate-x-0"
    aria-label="Sidebar"
  >
    <SidebarMenu :isCollapsed="isAsideCollapsed"/>
    
    <div class="collapse-button" @click="toggleAside" :class="[{'rotate-180': isAsideCollapsed}]"> 
      <CollapseIcon />
    </div>

    <SidebarNotifications v-if="!isAsideCollapsed" />
    
  </aside>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import SidebarMenu from "@/components/basic/navbar/aside/SidebarMenu.vue";
import SidebarNotifications from "@/components/basic/navbar/aside/SidebarNotifications.vue";
import { CollapseIcon } from "@/components/svg/icons/IconsComponents";

export default defineComponent({
  name: "SideBar",
  components: {
    SidebarMenu,
    SidebarNotifications,
    CollapseIcon,
  },
  setup() {
    const store = useStore();
    const isLogged = computed(() => store.state.isLogged);
    const isAsideCollapsed = computed(() => store.getters.isAsideCollapsed);
    const toggleAside = () => {
      store.commit('toggleAside');
    };
    return {
      isLogged,
      isAsideCollapsed,
      toggleAside,
    };
  },
});
</script>