import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "stepper-container" }
const _hoisted_2 = { class: "stepper-box" }
const _hoisted_3 = { class: "step-index" }
const _hoisted_4 = { class: "stepper-head" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: "stepper" }
const _hoisted_8 = ["data-tooltip-target", "disabled", "onClick", "onMouseover"]
const _hoisted_9 = {
  key: 0,
  class: "align-end",
  "aria-hidden": "true",
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 12 10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courses, (course, courseIndex) => {
        return (_openBlock(), _createElementBlock("li", {
          key: course.id,
          class: "stepper-item"
        }, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(courseIndex + 1), 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              class: "stepper-title",
              onClick: ($event: any) => (_ctx.setCourseId(course.id))
            }, _toDisplayString(course.title), 9, _hoisted_5),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(course.steps, (step, stepIndex) => {
                  return (_openBlock(), _createElementBlock("button", {
                    key: stepIndex,
                    class: _normalizeClass(["stepper-step", {
                  'bg-green-500 text-green-100': step.status === 'completed',
                  'bg-yellow-500 text-yellow-100':
                    step.status === 'in-progress',
                  'bg-gray-400 text-gray-100 disabled opacity-30':
                    step.status === 'not-started',
                }]),
                    "data-tooltip-target": `tooltip-${stepIndex}`,
                    "data-tooltip-placement": "bottom",
                    type: "button",
                    disabled: step.status === 'not-started',
                    onClick: ($event: any) => (_ctx.handleStepClick(course.id, step)),
                    onMouseover: ($event: any) => (_ctx.showTooltip($event, courseIndex, stepIndex )),
                    onMouseleave: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hideTooltip && _ctx.hideTooltip(...args)))
                  }, null, 42, _hoisted_8))
                }), 128))
              ])
            ])
          ]),
          (courseIndex < _ctx.courses.length - 1)
            ? (_openBlock(), _createElementBlock("svg", _hoisted_9, _cache[1] || (_cache[1] = [
                _createElementVNode("path", {
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  "stroke-width": "2",
                  d: "m7 9 4-4-4-4M1 9l4-4-4-4"
                }, null, -1)
              ])))
            : _createCommentVNode("", true)
        ]))
      }), 128)),
      (_ctx.tooltipCourseIndex !== null && _ctx.tooltipStepIndex !== null)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "tooltip",
            style: _normalizeStyle(_ctx.tooltipStyle)
          }, _toDisplayString(_ctx.courses[_ctx.tooltipCourseIndex].steps[_ctx.tooltipStepIndex].title), 5))
        : _createCommentVNode("", true)
    ])
  ]))
}