import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "sidebar-menu" }
const _hoisted_2 = { class: "space-y-2 font-medium" }
const _hoisted_3 = {
  key: 0,
  class: "ms-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, (route) => {
        return (_openBlock(), _createElementBlock("li", {
          clas: "",
          key: route.name
        }, [
          _createVNode(_component_router_link, {
            to: route.href,
            class: "sidebar-menu-item flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(route.icon), { class: "w-6 h-6 ms-1" })),
              (!_ctx.isCollapsed)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(route.name), 1))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128))
    ])
  ]))
}