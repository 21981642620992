<template>
  

    <div @submit.prevent="handleSubmit" class="w-full m-auto max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
        <form class="space-y-6" action="#">
            <h5 class="text-xl font-medium text-gray-900 dark:text-white">Sign up to our platform</h5>
            <div>
                <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                <input type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="name@company.com" required />
            </div>
            <div>
                <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your password</label>
                <input type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
            </div>
            <div class="flex items-start">
                <div class="flex items-start">
                    <div class="flex items-center h-5">
                        <input id="remember" type="checkbox" value="" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800" required />
                    </div>
                    <label for="remember" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Remember me</label>
                </div>
                <a href="#" class="ms-auto text-sm text-blue-700 hover:underline dark:text-blue-500">Lost Password?</a>
            </div>
            <button type="submit" class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Create account</button>
            <div class="text-sm font-medium text-gray-500 dark:text-gray-300">
                Already registered? <a href="#" class="text-blue-700 hover:underline dark:text-blue-500">I have account</a>
            </div>
        </form>
    </div>
    
    </template>
    
    <script lang="ts">
    import { defineComponent, ref, onMounted, computed } from "vue";
    import { useStore } from 'vuex';
    import FingerprintJS from '@fingerprintjs/fingerprintjs';
    
    
    export default defineComponent({
      name: "SignUpForm",
      setup() {
        const store = useStore();
        const email = ref("");
        const password = ref("");
        const remember = ref(false);
        const authServerStatus = computed(() => store.state.authServiceStatus);
    
        const handleSubmit = async () => {
          try {
            // Получение фингерпринта
            const fp = await FingerprintJS.load();
            const result = await fp.get();
            const fingerprint = result.visitorId;
    
            await store.dispatch('login', { email: email.value, password: password.value, fingerprint });
            alert('Авторизация успешна');
          } catch (error) {
            alert('Ошибка авторизации');
          }
        };
    
        
    
        onMounted(() => {
          store.dispatch('pingAuthServer');
        });
    
        return {
          email,
          password,
          remember,
          authServerStatus,
          handleSubmit,
        };
      },
    });
    </script>