<template>
    <SignInForm/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import SignInForm from '@/components/auth/SignInForm.vue';

export default defineComponent({
    name: 'SigninView',
    components: {
        SignInForm,
    },
});
</script>