import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarMenu = _resolveComponent("SidebarMenu")!
  const _component_CollapseIcon = _resolveComponent("CollapseIcon")!
  const _component_SidebarNotifications = _resolveComponent("SidebarNotifications")!

  return (_ctx.isLogged)
    ? (_openBlock(), _createElementBlock("aside", {
        key: 0,
        class: _normalizeClass([['aside', { collapsed: _ctx.isAsideCollapsed }], "sidebar w-64 h-screen transition-transform -translate-x-full sm:translate-x-0"]),
        id: "logo-sidebar",
        "aria-label": "Sidebar"
      }, [
        _createVNode(_component_SidebarMenu, { isCollapsed: _ctx.isAsideCollapsed }, null, 8, ["isCollapsed"]),
        _createElementVNode("div", {
          class: _normalizeClass(["collapse-button", [{'rotate-180': _ctx.isAsideCollapsed}]]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleAside && _ctx.toggleAside(...args)))
        }, [
          _createVNode(_component_CollapseIcon)
        ], 2),
        (!_ctx.isAsideCollapsed)
          ? (_openBlock(), _createBlock(_component_SidebarNotifications, { key: 0 }))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}