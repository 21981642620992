import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/courses/3.jpg'


const _hoisted_1 = { class: "task" }
const _hoisted_2 = { class: "p-5" }
const _hoisted_3 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("a", { href: "#" }, [
      _createElementVNode("img", {
        class: "rounded-t-lg",
        src: _imports_0,
        alt: ""
      })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("a", { href: "#" }, [
        _createElementVNode("h5", { class: "mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" }, " Fill form ")
      ], -1)),
      _cache[2] || (_cache[2] = _createElementVNode("p", { class: "mb-3 font-normal text-gray-700 dark:text-gray-400" }, " Check your steps on the left and choose the best course for you. Click on the course to see more details. ", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          id: "drawer-button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openDrawer())),
          class: "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800",
          type: "button",
          "data-drawer-target": "course-drawer",
          "data-drawer-show": "course-drawer",
          "data-drawer-placement": "right",
          "aria-controls": "course-drawer"
        }, " Show right drawer ")
      ])
    ])
  ]))
}