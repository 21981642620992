<template>
  <div class="main">
    <NavbarTop :isLogged="isLogged"/>
    
    <div :class="['content', {  'full': !isLogged }]">
      <SideBar />
      <div :class="{ 'content-container': isLogged }">
        <router-view />
      </div>
      
    </div>
  </div>
  
</template>

<script>
import { defineComponent,ref, watch, onMounted } from "vue";
import NavbarTop from './components/basic/navbar/NavbarTop.vue'
import SideBar from "./components/basic/navbar/SideBar.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: 'App',
  components: {
    NavbarTop,
    SideBar,
  },
  setup() {
    const store = useStore()
    const isLogged = ref(store.getters.isLogged);
    
    watch(
      () => store.getters.isLogged,
      (newIsLogged) => {
        isLogged.value = newIsLogged;
        console.log('isLogged updated:', newIsLogged);
      },
      { immediate: true }
    );

    return {
      isLogged,
    }
  },
})
</script>


<style lang="scss">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}



.main {
  display: flex;
  flex-direction: column;
  height: 90vh
}
</style>
