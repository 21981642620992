import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "theme-switcher" }
const _hoisted_2 = { class: "icon icon-sun" }
const _hoisted_3 = { class: "icon icon-moon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SunIcon = _resolveComponent("SunIcon")!
  const _component_MoonIcon = _resolveComponent("MoonIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['icon-container', { flipped: _ctx.isFlipped }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleTheme && _ctx.toggleTheme(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_SunIcon)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_MoonIcon)
      ])
    ], 2)
  ]))
}