<template>
  <div class="user-profile">
    <span class="sr-only">Open user menu</span>
    <div
      class="user-icon"
      type="button"
      id="user-menu-button"
      aria-expanded="false"
      data-dropdown-toggle="user-dropdown"
      data-dropdown-placement="bottom"
    >
      <UnderfindUserIcon />
    </div>

    <!-- Dropdown menu -->
    <div
      class="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
      id="user-dropdown"
    >
      <div class="px-4 py-3">
        <span class="block text-sm text-gray-900 dark:text-white">
          <router-link
            v-if="userId"
            :to="{ name: 'user', params: { id: userId } }"
            class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
          >
            {{ userInfo.name }}
          </router-link>
        </span>
        <span class="block text-sm text-gray-500 truncate dark:text-gray-400"
          >{{ userInfo.email }}</span
        >
      </div>
      <ul class="py-2" aria-labelledby="user-menu-button">
        <!-- <li>
          <a
            href="#"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
            >Dashboard</a
          >
        </li> -->
        <li>
          <a
            href="#"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
            >Settings</a
          >
        </li>

        <li>
          <a
            @click="logout"
            href="#"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
            >Sign out</a
          >
        </li>
      </ul>
    </div>
    <button
      data-collapse-toggle="navbar-user"
      type="button"
      class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      aria-controls="navbar-user"
      aria-expanded="false"
    >
      <span class="sr-only">Open main menu</span>
      <svg
        class="w-5 h-5"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 17 14"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M1 1h15M1 7h15M1 13h15"
        />
      </svg>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { UnderfindUserIcon } from "@/components/svg/icons/IconsComponents";

export default defineComponent({
  name: "NavbarTop",
  components: {
    UnderfindUserIcon,
  },
  props: {
    isLogged: Boolean,
  },
  setup() {
    const store = useStore();
    const userInfo = computed(() => store.getters.userInfo);
    const userId = ref(null);

    const logout = async () => {
      try {
        await store.dispatch("requestLogout");
      } catch (error) {
        console.error(error);
      }
    };

    // const familyId = 1;
    // console.log(userInfo);
    watch(
      () => userInfo.value,
      (newUserInfo) => {
        if (newUserInfo && newUserInfo.id) {
          userId.value = newUserInfo.id;
          console.log("userId:", userId.value);
        }
      },
      { immediate: true }
    );

    return {
      userInfo,
      userId,
      logout,
    };
  },
});
</script>
