import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "bg-white",
  style: {"width":"30rem"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isDrawerOpen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          id: "course-drawer-overlay",
          class: _normalizeClass(["course-drawer-overlay fixed inset-0 bg-black transition-opacity duration-300", { 'opacity-20': _ctx.isDrawerOpen, 'opacity-0': !_ctx.isDrawerOpen }]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeDrawer && _ctx.closeDrawer(...args)))
        }, null, 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      id: "course-drawer",
      class: _normalizeClass([
        'course-drawer-container fixed top-0 right-0 z-50 h-screen p-4 overflow-y-auto transition-transform duration-300 bg-white dark:bg-gray-800',
        { 'translate-x-full': !_ctx.isDrawerOpen, 'translate-x-0': _ctx.isDrawerOpen },
      ]),
      tabindex: "-1",
      "aria-labelledby": "course-drawer-label",
      onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", {
          type: "button",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeDrawer && _ctx.closeDrawer(...args))),
          "aria-controls": "course-drawer",
          class: "text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("svg", {
            class: "w-3 h-3",
            "aria-hidden": "true",
            xmlns: "http://www.w3.org/2000/svg",
            fill: "none",
            viewBox: "0 0 14 14"
          }, [
            _createElementVNode("path", {
              stroke: "currentColor",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "2",
              d: "m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            })
          ], -1),
          _createElementVNode("span", { class: "sr-only" }, "Close menu", -1)
        ])),
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.drawerComponent)))
      ])
    ], 2)
  ], 64))
}