<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    viewBox="0 0 256 256"
    xml:space="preserve"
  >
    <defs></defs>
    <g
      style="
        stroke: none;
        stroke-width: 0;
        stroke-dasharray: none;
        stroke-linecap: butt;
        stroke-linejoin: miter;
        stroke-miterlimit: 10;
        fill: none;
        fill-rule: nonzero;
        opacity: 1;
      "
      transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
    >
      <path
        d="M 2.571 30 l 84.859 0 C 81.254 12.534 64.611 0.015 45.034 0 l -0.068 0 C 25.389 0.015 8.745 12.534 2.571 30 z"
        style="
          stroke: none;
          stroke-width: 1;
          stroke-dasharray: none;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-miterlimit: 10;
          fill: rgb(243, 244, 245);
          fill-rule: nonzero;
          opacity: 1;
        "
        transform=" matrix(1 0 0 1 0 0) "
        stroke-linecap="round"
      />
      <path
        d="M 87.429 60 L 2.571 60 C 8.75 77.476 25.408 90 45 90 S 81.25 77.476 87.429 60 z"
        style="
          stroke: none;
          stroke-width: 1;
          stroke-dasharray: none;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-miterlimit: 10;
          fill: rgb(213, 43, 30);
          fill-rule: nonzero;
          opacity: 1;
        "
        transform=" matrix(1 0 0 1 0 0) "
        stroke-linecap="round"
      />
      <path
        d="M 87.429 60 C 89.088 55.307 90 50.261 90 45 c 0 -5.261 -0.911 -10.307 -2.571 -15 L 2.571 30 C 0.911 34.693 0 39.739 0 45 c 0 5.261 0.912 10.308 2.571 15 L 87.429 60 z"
        style="
          stroke: none;
          stroke-width: 1;
          stroke-dasharray: none;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-miterlimit: 10;
          fill: rgb(0, 57, 166);
          fill-rule: nonzero;
          opacity: 1;
        "
        transform=" matrix(1 0 0 1 0 0) "
        stroke-linecap="round"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "FlagRuIcon",
});
</script>
