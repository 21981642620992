// import Cookies from "js-cookie";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { ActionContext } from "vuex";
import { RootState, LoginCredentials } from "@/types/types";
import { getTokenExpiration, login, logout } from "@/services/auth/authService";

// Получение адреса сервера из переменной окружения
const AUTH_URL = process.env.VUE_APP_AUTH_URL || "http://localhost:3000";
const USER_URL = process.env.VUE_APP_USER_URL || "http://localhost:3000";

// Функция для отправки запроса авторизации
export const actions = {
  // Функция для пинга сервера авторизации
  async pingAuthServer({ commit }: ActionContext<RootState, RootState>) {
    try {
      const response = await fetch(`${AUTH_URL}/ping`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      // console.log(AUTH_URL);
      // console.log("response status:", response.status);
      if (response.status != 200) {
        // console.error("Ошибка при пинге сервера авторизации:", response);
      }

      const data = await response.json();
      commit("setAuthServiceStatus", data.status);
      console.log("Статус сервера авторизации:", data.status);
    } catch (error) {
      console.error("Ошибка при пинге сервера авторизации");
      commit("setAuthServiceStatus", "error");
    }
  },

  // Функция для отправки запроса авторизации
  async requestLogin(
    { commit }: ActionContext<RootState, RootState>,
    credentials: LoginCredentials
  ) {
    try {

      const data = await login(credentials);

      if (!data) {
        throw new Error("Ошибка при авторизации");
      }

      const tokenExpiration = await getTokenExpiration();

      // Сохранение токенов в cookies
      // Cookies.set("accessToken", accessToken, {secure: false, sameSite: 'None' });
      // Cookies.set("refreshToken", refreshToken, {secure: false, sameSite: 'None' });

      // Сохранение токенов в sessionStorage
      sessionStorage.setItem("userId", data.userId);
      sessionStorage.setItem("accessToken", data.accessToken);
      sessionStorage.setItem("refreshToken", data.refreshToken);
      sessionStorage.setItem("tokenExpiration", tokenExpiration.toString());
      sessionStorage.setItem("isLogged", "true");

      // Логирование значений для проверки
      // console.log("accessToken:", sessionStorage.getItem("accessToken"));
      // console.log("refreshToken:", sessionStorage.getItem("refreshToken"));

      // Коммитим мутацию для обновления состояния
      commit("setAccessToken", data.accessToken);
      commit("setRefreshToken", data.refreshToken);
      commit("setUserId", data.userId);
      // Устанавливаем статус авторизации
      commit("setLoggedStatus", true);

    } catch (error) {
      console.error("Ошибка при авторизации:", error);
      throw error;
    }
  },

  // Функция для получения информации о пользователе по его ID
  async getUserInfoById(
    { commit }: ActionContext<RootState, RootState>,
    { userId }: { userId: string; expand?: string }
  ): Promise<any> {
    const token = sessionStorage.getItem("accessToken");
    //const token = Cookies.get("accessToken");
    console.log("Токен:", token);
    if (!token) {
      throw new Error("Токен не найден");
    }
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const fingerprint = result.visitorId;
    try {
      const response = await fetch(`${USER_URL}/users/${userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-nb-fingerprint": fingerprint,
          Authorization: `Bearer ${token}`,
        },
        // credentials: 'include',
      });

      if (!response.ok) {
        console.log("Ошибка при получении информации о пользователе");
      }

      const data = await response.json();

      const userInfo = {
        id: data.id,
        email: data.email,
        name: data.name,
        surname: "",
        avatar: data.avatar,
        role: "user",
        familyId: 1,
      };
      console.log("Информация о пользователе:", userInfo);
      commit("setUserInfo", userInfo);

      // console.log("Информация о пользователе:", data);

      return data;
    } catch (error) {
      console.error("Ошибка при получении информации о пользователе:", error);
      throw error;
    }
  },

  async changeUserInfo ({ commit }: ActionContext<RootState, RootState>) {

    const token = sessionStorage.getItem("accessToken");
    if (!token) {
      throw new Error("Токен не найден");
    }

    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const fingerprint = result.visitorId;

    const userId = sessionStorage.getItem("userId");
    const customFields = {

      "customFields": {
          "isAdmin": true,
          "familyId": 5
      }
  
  }

    try {
      const response = await fetch(`${USER_URL}/users/${userId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "x-nb-fingerprint": fingerprint,
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(customFields),
      });

      if (!response.ok) {
        throw new Error("Ошибка при изменении информации о пользователе");
      }

      const responseData = await response.json();
      console.log("Информация о пользователе после изменений:", responseData);

      return responseData;
    } catch (error) {
      console.error("Ошибка при изменении информации о пользователе:", error);
      throw error;
    }
  },

  async requestLogout() {
    await logout();
  }

  
};
