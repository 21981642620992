<template>
    <div class="language-switcher" @click="toggleDropdown" >
        <component :is="currentFlag"></component>
    </div>

    <div :class="['dropdown-container', { hidden: !isDropdownOpen, block: isDropdownOpen }]" id="languages-dropdown">
        <div class="languages-dropdown" v-for="(lang, index) in filteredLanguages" :key="index" aria-labelledby="dropdownLanguages">
            <div :class="['lang-item' , `lang-${lang.key}`]" @click="selectLang(lang.key)">
                <component :is="lang.value"></component>
            </div>
            </div>
    </div>
    
</template>

<script lang="ts">
import { defineComponent, computed, ref } from "vue";
import { FlagRuIcon, FlagEnIcon, FlagJpIcon } from "@/components/svg/icons/IconsComponents";
import { useStore } from "vuex";

export default defineComponent({
  name: "LanguageSwitcher",
    components: {
        FlagRuIcon,
        FlagEnIcon,
        FlagJpIcon,
    },
  props: {
    isDarkMode: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const store = useStore();
    const isDropdownOpen = ref(false);
    const languages = {
        ru: "FlagRuIcon",
        en: "FlagEnIcon",
        jp: "FlagJpIcon",
    }
    const lang = computed(() => store.getters.getLanguge);
    const currentFlag = computed(() => {
        switch (lang.value) {
            case "ru":
                return "FlagRuIcon";
            case "en":
                return "FlagEnIcon";
            case "jp":
                return "FlagJpIcon";
            default:
                return "FlagEnIcon";
        }
    });
    
    const toggleDropdown = () => {
      isDropdownOpen.value = !isDropdownOpen.value;
    };



    const selectLang = (lang: string) => {
        store.commit("setLanguage", lang);
        isDropdownOpen.value = false;
    };

    const filteredLanguages = computed(() => {
      return Object.entries(languages)
        .filter(([key]) => key !== lang.value)
        .map(([key, value]) => ({ key, value }));
    });

    return {
        languages,
        lang,
        currentFlag,
        selectLang,
        toggleDropdown,
        isDropdownOpen,
        filteredLanguages
    };
  },
});

</script>