import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "language-switcher",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
    }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentFlag)))
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(['dropdown-container', { hidden: !_ctx.isDropdownOpen, block: _ctx.isDropdownOpen }]),
      id: "languages-dropdown"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredLanguages, (lang, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "languages-dropdown",
          key: index,
          "aria-labelledby": "dropdownLanguages"
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(['lang-item' , `lang-${lang.key}`]),
            onClick: ($event: any) => (_ctx.selectLang(lang.key))
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(lang.value)))
          ], 10, _hoisted_1)
        ]))
      }), 128))
    ], 2)
  ], 64))
}