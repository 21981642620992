import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  version: "1.1",
  viewBox: "0 0 256 256",
  "xml:space": "preserve"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("defs", null, null, -1),
    _createElementVNode("g", {
      style: {"stroke":"none","stroke-width":"0","stroke-dasharray":"none","stroke-linecap":"butt","stroke-linejoin":"miter","stroke-miterlimit":"10","fill":"none","fill-rule":"nonzero","opacity":"1"},
      transform: "translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
    }, [
      _createElementVNode("circle", {
        cx: "45",
        cy: "45",
        r: "45",
        style: {"stroke":"none","stroke-width":"1","stroke-dasharray":"none","stroke-linecap":"butt","stroke-linejoin":"miter","stroke-miterlimit":"10","fill":"#fff","fill-rule":"nonzero","opacity":"1"},
        transform: "  matrix(1 0 0 1 0 0) "
      }),
      _createElementVNode("circle", {
        cx: "45",
        cy: "45",
        r: "17.5",
        style: {"stroke":"none","stroke-width":"1","stroke-dasharray":"none","stroke-linecap":"butt","stroke-linejoin":"miter","stroke-miterlimit":"10","fill":"rgb(211, 0, 0)","fill-rule":"nonzero","opacity":"1"},
        transform: "  matrix(1 0 0 1 0 0) "
      })
    ], -1)
  ])))
}