import { createStore } from "vuex";
import { state } from "./states/state";
import { mutations } from "./mutations/mutations";
import { actions } from "./actions/actions";
import { getters } from "./getters/getters";

export default createStore({
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
  modules: {},
});
