<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    viewBox="0 0 256 256"
    xml:space="preserve"
  >
    <defs></defs>
    <g
      style="
        stroke: none;
        stroke-width: 0;
        stroke-dasharray: none;
        stroke-linecap: butt;
        stroke-linejoin: miter;
        stroke-miterlimit: 10;
        fill: none;
        fill-rule: nonzero;
        opacity: 1;
      "
      transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
    >
      <circle
        cx="45"
        cy="45"
        r="45"
        style="
          stroke: none;
          stroke-width: 1;
          stroke-dasharray: none;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-miterlimit: 10;
          fill: #fff;
          fill-rule: nonzero;
          opacity: 1;
        "
        transform="  matrix(1 0 0 1 0 0) "
      />
      <circle
        cx="45"
        cy="45"
        r="17.5"
        style="
          stroke: none;
          stroke-width: 1;
          stroke-dasharray: none;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-miterlimit: 10;
          fill: rgb(211, 0, 0);
          fill-rule: nonzero;
          opacity: 1;
        "
        transform="  matrix(1 0 0 1 0 0) "
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "FlagJpIcon",
  setup() {
    return {
      // Remove the duplicated key 'isDarkMode'
      // isDarkMode,
    };
  },
});
</script>
