import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import 'flowbite';

//Icons
import '@/components/svg/icons/IconsComponents'
// Flowbite
import './index.css'
// Styles
import '@/css/main.scss'
import '@/css/navbar.scss'
import '@/css/aside.scss'
import '@/css/home.scss'
import '@/css/stepper.scss'

//Templates

createApp(App).use(store).use(router).mount("#app");
