import { GetterTree } from 'vuex';
import { RootState } from '@/types/types';

export const getters: GetterTree<RootState, RootState> = {
  getLanguge: (state) => {
    return state.language;
  },
  accessToken: (state) => {
    return state.accessToken;
  },
  refreshToken: (state) => {
    return state.refreshToken;
  },
  isLogged: (state) => {
    return state.isLogged;
  },
  authServiceStatus: (state) => {
    return state.authServiceStatus;
  },
  userInfo: (state) => {
    return state.userInfo;
  },
  userId: (state) => {
    return state.userInfo.id.valueOf();
  },
  isDrawerOpen: (state) => state.isDrawerOpen,

  courseId: (state) => state.courseInfo.id,

  isAsideCollapsed: (state) => state.isAsideCollapsed,
};