<template>
    <svg width="2rem" height="2.1rem" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
  <!-- Первая диагональная линия -->
  <line x1="10" y1="55" x2="35" y2="10" stroke="#A5B68D" stroke-width="3"/>
  <!-- Вторая диагональная линия -->
  <line x1="25" y1="55" x2="50" y2="10" stroke="#A5B68D" stroke-width="3"/>
</svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "2DiagLines",
});

</script>