<template>
    <div
      class="task"
    >
      <a href="#">
        <img class="rounded-t-lg" src="@/assets/img/courses/2.jpg" alt="" />
      </a>
      <div class="p-5">
        <a href="#">
          <h5
            class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
          >
            Interview
          </h5>
        </a>
        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
          Check your steps on the left and choose the best course for you. Click on the course to see more details.
        </p>
        <!-- drawer init and toggle -->
        <!-- <div class="text-center">
          <button id="drawer-button" @click="openDrawer()" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" type="button" data-drawer-target="course-drawer" data-drawer-show="course-drawer" data-drawer-placement="right" aria-controls="course-drawer">
          Show right drawer
          </button> -->
<!-- </div> -->
      </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "CourseInterview",
  setup() {
    const store = useStore();
    const openDrawer = () => {
      store.commit("openDrawer", "course-drawer");
    };

    return {
      openDrawer,
    };
  },
});

</script>