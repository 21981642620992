import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700" }
const _hoisted_2 = { class: "flex flex-col items-center pb-10" }
const _hoisted_3 = { class: "mb-1 text-xl font-medium text-gray-900 dark:text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"flex justify-end px-4 pt-4\"><button id=\"dropdownButton\" data-dropdown-toggle=\"dropdown\" class=\"inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5\" type=\"button\"><span class=\"sr-only\">Open dropdown</span><svg class=\"w-5 h-5\" aria-hidden=\"true\" xmlns=\"http://www.w3.org/2000/svg\" fill=\"currentColor\" viewBox=\"0 0 16 3\"><path d=\"M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z\"></path></svg></button><div id=\"dropdown\" class=\"z-10 hidden text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700\"><ul class=\"py-2\" aria-labelledby=\"dropdownButton\"><li><a href=\"#\" class=\"block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white\">Edit</a></li><li><a href=\"#\" class=\"block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white\">Export Data</a></li><li><a href=\"#\" class=\"block px-4 py-2 text-sm text-red-600 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white\">Delete</a></li></ul></div></div>", 1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("img", {
        class: "w-24 h-24 mb-3 rounded-full shadow-lg",
        src: "/docs/images/people/profile-picture-3.jpg",
        alt: "User image"
      }, null, -1)),
      _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.userInfo.name), 1),
      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-sm text-gray-500 dark:text-gray-400" }, "Admin", -1))
    ])
  ]))
}